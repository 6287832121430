* {
  /* 去除默认边距 */
  margin: 0;
  padding: 0;
  /* 元素计算边框大小 */
  box-sizing: border-box;
}

/*整个滚动条*/
::-webkit-scrollbar {
  width: 1px;
  background-color: rgba(255, 255, 255, 0);
}

/*定义滚动条轨道*/

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}

/*定义滑块*/

::-webkit-scrollbar-thumb {
  background-color: #acb1bf00;
}

.Mui-selected {
  background-color: #3291ff !important;
  color: #ffffff !important;
}
/* .MuiPaper-root {
  max-width: 1000px !important;
} */

@font-face {
  font-family: "Roboto";
  src: url(../font/video/Roboto-Regular.ttf);
}
@font-face {
  font-family: "Roboto Italic";
  src: url(../font/video/Roboto-MediumItalic.ttf);
}
@font-face {
  font-family: "Montserrat";
  src: url(../font/video/Montserrat-VariableFont_wght.ttf);
}
@font-face {
  font-family: "Merriweather";
  src: url(../font/video/Merriweather-Regular.ttf);
}
@font-face {
  font-family: "NotoSans";
  src: url(../font/video/NotoSans-Regular.ttf);
}
@font-face {
  font-family: "NotoSerif";
  src: url(../font/video/NotoSerif-Regular.ttf);
}
@font-face {
  font-family: "Oswald";
  src: url(../font/video/Oswald-Regular.ttf);
}
@font-face {
  font-family: "OpenSans";
  src: url(../font/video/OpenSans-Regular.ttf);
}
@font-face {
  font-family: "Bitter";
  src: url(../font/video/Bitter-Regular.ttf);
}
@font-face {
  font-family: "Rubik";
  src: url(../font/video/Rubik-Regular.ttf);
}
@font-face {
  font-family: "Poppins";
  src: url(../font/video/Poppins-Regular.ttf);
}
@font-face {
  font-family: "myFont";
  src: url(../font/video/Quicksand-SemiBold.ttf);
}
