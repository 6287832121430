@keyframes turn {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
    }
}

.k-line {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #3291ff;
    margin-left: 30px;
}

@keyframes k-loadingA {
    50% {
        height: 150px;
    }
    100% {
        height: 60px;
    }
}

.k-lineA-1 {
    animation: k-loadingA 1.5s 0s infinite;
}

.k-lineA-2 {
    animation: k-loadingA 1.5s 0.3s infinite;
}

.k-lineA-3 {
    animation: k-loadingA 1.5s 06s infinite;
}

.k-lineA-4 {
    animation: k-loadingA 1.5s 09s infinite;
}

.k-lineA-5 {
    animation: k-loadingA 1.5s 1.2s infinite;
}